import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocumsNestEndpointConfig } from 'apps/hospital-admin/src/app/core/constants';
import { CookieService } from 'ngx-cookie-service';
import { catchError, concat, EMPTY, map, mergeMap, Observable, of, tap, throwError } from 'rxjs';

import { AccountPersistenceService } from '@locumsnest/auth/src/account/services/account.persistence.service';
import { HttpEndpointService, IQueryParams } from '@locumsnest/core';
import { unquoteSecureCookieValue } from '@locumsnest/core/src/lib/helpers/util';

import { IHomePageService } from './interfaces';
import {
  AUTH_USER_TYPE_COOKIE,
  AUTH_USER_TYPE_COOKIE_EXPECTED_VALUE,
  HOME_PAGE_SERVICE,
} from './opaque-tokens';

@Injectable()
export class AuthService {
  private hasSession = false;
  private triggerLogout = false;

  constructor(
    private router: Router,
    private accountPersistenceService: AccountPersistenceService,
    private cookieService: CookieService,
    private httpEndpointService: HttpEndpointService<LocumsNestEndpointConfig>,
    // To skip interceptor
    private httpBackend: HttpBackend,
    @Inject(AUTH_USER_TYPE_COOKIE) private authUserTypeCookieName: string,
    @Inject(AUTH_USER_TYPE_COOKIE_EXPECTED_VALUE) private authUserTypeExpectedValue: string,
    @Inject(HOME_PAGE_SERVICE) private homePageService: IHomePageService,
  ) {
    // TODO do we need this?
    // this.hasSession = this.isAuthenticatedWithExpectedUser();
  }

  isLoggedIn() {
    return this.hasSession;
  }

  isAuth(): Observable<boolean> {
    // this is called only once on app initialiser
    const endpoint = this.httpEndpointService.getEndpoint('authGroupCurrent');
    const client = new HttpClient(this.httpBackend);

    return client.get(endpoint).pipe(
      map(() => {
        this.hasSession = true;
        return true;
      }),
      catchError(() => of(false)),
    );
  }

  createSession() {
    this.hasSession = true;
    return EMPTY;
  }

  login(loginParams: IQueryParams) {
    return concat(
      this.accountPersistenceService.login(loginParams).pipe(mergeMap(() => this.createSession())),
      this.homePageService.redirectToHome(),
    );
  }

  destroySession(redirectUrl?: string, reload?: boolean) {
    this.hasSession = false;
    const queryParams = {};
    if (redirectUrl && !['/logout', 'login'].includes(redirectUrl))
      Object.assign(queryParams, { redirectUrl });
    this.cookieService.delete(this.authUserTypeCookieName);

    if (reload)
      setTimeout(() => {
        location.reload();
        this.triggerLogout = false;
      }, 0);
    return this.router.navigate(['login'], { queryParams });
  }

  logout() {
    if (this.hasSession && !this.triggerLogout) {
      this.triggerLogout = true;
      return this.accountPersistenceService.logout().pipe(tap(() => this.destroySession('', true)));
    }

    return throwError(() => 'You are not logged in');
  }

  private isAuthenticatedWithExpectedUser() {
    return (
      unquoteSecureCookieValue(this.cookieService.get(this.authUserTypeCookieName)) ===
      this.authUserTypeExpectedValue
    );
  }
}
