import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '@locumsnest/auth/src/lib/session-auth/services/auth.service';
import { WINDOW } from '@locumsnest/core/src/lib/services/window.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private found401 = false;
  private window = inject(WINDOW);
  private authService = inject(AuthService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((response: unknown) => {
        if (
          response instanceof HttpErrorResponse &&
          response.status === HttpStatusCode.Unauthorized
        ) {
          //this is just a flag and not a security measure
          // if redirect fails and use is not logged in requests
          // will fail but we need to let the auth guard know
          this.handle401Error();
        }
        return throwError(() => response);
      }),
    );
  }
  // avoid multi 401s
  private handle401Error() {
    if (!this.found401) {
      this.found401 = true;
      this.authService.destroySession(this.window.location.pathname, true);
    }
  }
}
