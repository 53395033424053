import { Injectable } from '@angular/core';

import { HttpApiPersistenceService, IQueryParams } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../../../../apps/hospital-admin/src/app/core/constants';
import {
  ILoginEntity,
  IPasswordChangeEntity,
  IRecoveryEntity,
  IRecoveryRequestEntity,
} from '../../../../../apps/hospital-admin/src/app/interfaces/api/account-entity';

@Injectable({
  providedIn: 'root',
})
export class AccountPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  {}
> {
  protected readonly endpoint = 'account';

  passwordChange(queryParams: IQueryParams) {
    return this.create<{}, IPasswordChangeEntity>(queryParams, {
      controllerResource: 'passwordChange',
      skipSerializer: true,
    });
  }

  confirmRecovery(queryParams: IQueryParams) {
    return this.create<{}, IRecoveryEntity>(queryParams, {
      controllerResource: 'recoveryConfirmation',
      skipSerializer: true,
    });
  }

  login(queryParams: IQueryParams) {
    return this.create<{}, ILoginEntity>(queryParams, {
      controllerResource: 'login',
      skipSerializer: true,
    });
  }

  logout() {
    return this.create<{}, {}>({}, { controllerResource: 'logout', skipSerializer: true });
  }

  recoveryRequest(queryParams: IQueryParams) {
    return this.create<{}, IRecoveryRequestEntity>(queryParams, {
      controllerResource: 'recoveryRequest',
      skipSerializer: true,
    });
  }
}
