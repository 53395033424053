import { inject } from '@angular/core';
import { CanMatchFn, Route, Router } from '@angular/router';
import { first, map } from 'rxjs';

import { RouterService } from '@locumsnest/core/src/lib/router/router.service';

import { AuthService } from './auth.service';
import { HOME_PAGE_SERVICE } from './opaque-tokens';

export const authGuardService: CanMatchFn = (route: Route) => {
  const auth = inject(AuthService);
  const router = inject(Router);
  const routerService = inject(RouterService);

  if (!auth.isLoggedIn()) {
    return auth.destroySession(window.location.pathname);
  }

  const sandBoxPath = routerService.getSandBoxPath();

  if (sandBoxPath && !routerService.isSandBoxed(route['_urlSegment'] + '', sandBoxPath)) {
    router.navigateByUrl(sandBoxPath);
  }
  return true;
};

export const redirectGuardService: CanMatchFn = () => {
  const auth = inject(AuthService);
  const router = inject(Router);

  const homePageService = inject(HOME_PAGE_SERVICE);

  if (!auth.isLoggedIn()) {
    return auth.destroySession(window.location.pathname);
  }

  // return router.navigateByUrl('dashboard');

  return homePageService.getHomeUrl().pipe(
    first(),
    map((url) => router.parseUrl(url)),
  );
};
